<template>
    <div>
        <h5>{{ title }}</h5>
        <ul class="nav_menu_container">
            <li v-for="(item, i) in items" :key="i">
                <a class="h6" :href="item.link.url" :target="item.link.target">{{ item.link.title }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "NavMenu",
        props: {
            title: String,
            items: Array,
        }
    }
</script>

<style scoped lang="scss">
    h5 {
      margin-bottom: 1rem;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
    }

    .nav_menu_container {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: $white;
                font-size: 18px;
                font-weight: 500;
                line-height: 23px;
            }
        }
    }
</style>