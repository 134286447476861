import { render, staticRenderFns } from "./ChevronRight.vue?vue&type=template&id=07b5dc8e&scoped=true&inline"
import script from "./ChevronRight.vue?vue&type=script&lang=js&inline"
export * from "./ChevronRight.vue?vue&type=script&lang=js&inline"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b5dc8e",
  null
  
)

export default component.exports