<template>
    <footer class="page">
        <b-container>
            <b-row class="mb-5 pb-5">
                <b-col lg="5" class="mb-5">
                  <img class="logo_samsung mb-5" src="./../assets/media/logo/samsung.svg"/><br/>
                  <img class="logo_sft" src="./../assets/media/logo/white.svg"/><br/>
                  <img class="logo_ep mt-5" src="./../assets/media/logo/ep-logo.png"/>
                </b-col>
                <b-col>
                  <b-row class="mb-5">
                    <b-col cols="12" :md="12 / nav_menus.length" class="mb-5 mb-md-0" v-for="(nav, i) in nav_menus" :key="'nav_'+i" >
                      <nav-menu :title="nav.title" :items="nav.links"></nav-menu>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="3" class="text-lg-right d-flex flex-column justify-content-center">
                    <social-media></social-media>
                </b-col>
            </b-row>
            <b-row class="pt-0 pb-5" align-v="center">
                <b-col order="2" order-lg="1" cols="12" lg="auto">
                    <p class="h6 copyright">Design &amp; code by <a href="https://expansja.pl/" target="_blank">Expansja Advertising</a></p>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
    import SocialMedia from "./footer/SocialMedia";
    import NavMenu from "./footer/NavMenu";

    export default {
        name: "PageFooter",
        components: {NavMenu, SocialMedia},
        computed: {
            contact() {
                return this.$store.getters.setting('contact')
            },
            nav_menus() {
                return this.$store.getters.setting('nav')
            },
            docs() {
                return this.$store.getters.setting('docs')
            }
        }
    }
</script>

<style lang="scss">
    footer.page{
        padding-top: 80px;
        background: #1E1E1E;
        color: $white;

        a.h5{ color: $white; }
        .logo{ height: 140px; }

        .copyright{
            &, *{ color: $white; }
            @extend %header-6;
            margin: 0;
        }

        h5{
            margin-bottom: 16px;

            @include media-breakpoint-up(lg){ margin-bottom: 24px; }
        }
    }

    .contrast footer.page{
        background: $black;
        color: $white;

        .copyright{
            &, *{ color: $blue-100; }
        }
    }
</style>