<template>
    <ul class="nav-menu" v-if="menu && menu.items.length > 0">
      <menu-item v-for="(m,i) in root_menu" :key="i" :link="getUrl(m)" :title="m.title" :submenu="getSubMenu(m.ID)"/>
    </ul>
</template>

<script>
    import MenuItem from "./MenuItem";
    export default {
        name: "NavMenu",
        components: {MenuItem},
        computed: {
            menu() {
                return this.$store.getters.menu('topbar')
            },
            root_menu() {
                return this.menu.items.filter(m => m.menu_item_parent == 0)
            }
        },
        methods: {
            getSubMenu(id) {
                let sm = this.menu.items.filter(m => m.menu_item_parent == id)
                return sm;
            },
            getUrl(item) {
                return item.url.replace(/^.*\/\/[^/]+/, '')
            }
        }
    }
</script>

<style scoped lang="scss">
    .nav-menu{
        display: flex;
        flex-direction: column;
        list-style-type: none;
        //width: 100%;
        margin: 10px 0;
        padding: 0;

        @include media-breakpoint-up(lg){
            flex-direction: row;
            width: initial;
        }
      .register-form-menu-item {
        background-color: $primary;
        border-radius: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: $white;
        text-decoration: none;
      }
    }
</style>
